import React, { useState, useEffect, useRef, useCallback } from 'react';
import styled, { keyframes } from 'styled-components';
import apiService from '../../Services/apiService';
import { useComponentContext } from '../../Context/ComponentContext';
import { getRandomTilePosition, WindowWrapper } from './util';
import { DesktopWindow, IconData } from './types';
import { DesktopContainer, DesktopIcon, WindowFrame, TitleBar, TitleBarLeft, TitleBarText, TitleBarButtons, ContentArea, ResizeHandle, Taskbar, TaskbarButton, ContextMenuContainer, ContextMenuItem, IconLabel, IconSymbol, RubbishBinIcon, SystemGroup, GoogleLoginButton } from './styles';

const Desktop: React.FC = () => {
  // Track the windows the user has opened.
  const [windows, setWindows] = useState<DesktopWindow[]>([]);
  // Keep track of the highest zIndex in use.
  const [highestZ, setHighestZ] = useState(10);
  // The icons that are available to show on the desktop.
  const [loadedIcons, setLoadedIcons] = useState<IconData[]>([]);

  // Pull in the map of dynamically loaded components from our context.
  const components = useComponentContext();

  // State to manage the current video source
  const [currentVideo, setCurrentVideo] = useState('media/aa1.mp4');

  // Effect to change the video source every 10 seconds
  useEffect(() => {
    const videoSources = ['media/aa1.mp4', 'media/aa2.mp4', 'media/aa3.mp4', 'media/aa4.mp4', 'media/aa5.mp4'];

    const changeVideo = () => {
      const randomIndex = Math.floor(Math.random() * videoSources.length);
      setCurrentVideo(videoSources[randomIndex]);
    };

    const intervalId = setInterval(changeVideo, 5000); // Change video every 10 seconds

    return () => clearInterval(intervalId); // Cleanup on component unmount
  }, []);

  // Fetch icons (features) from the service, then attach the actual component (if any) from context.
  useEffect(() => {
    const fetchIcons = async () => {
      try {
        const response = await apiService.getFeatures();
        console.log('Response:', response);

        const iconData: IconData[] = [];
        for (const icon of response) {
          // If a componentName is provided AND we have it in context, attach it:
          if (icon.component && components[icon.component]) {
            iconData.push({
              ...icon,
              component: components[icon.component],
              tilePosition: icon.tilePosition || getRandomTilePosition(),
            });
          } else {
            // Either no componentName or it's not in our context. Just store the icon data.
            iconData.push({
              ...icon,
              tilePosition: icon.tilePosition || getRandomTilePosition(),
            });
          }
        }
        setLoadedIcons(iconData);
      } catch (error) {
        console.error('Error fetching icons:', error);
      }
    };

    fetchIcons();
  }, [components]);

  // Simple right-click menu state:
  const [contextMenu, setContextMenu] = useState<{
    visible: boolean;
    x: number;
    y: number;
    iconId: string | null;
  }>({ visible: false, x: 0, y: 0, iconId: null });

  /** Create or restore a window for the icon that was double-clicked. */
  const handleIconDoubleClick = (iconId: string) => {
    // Check if this window is already open.
    const existing = windows.find((w) => w.id === iconId);
    if (existing) {
      // If minimized, un‐minimize it.
      if (existing.minimized) {
        toggleMinimizeWindow(iconId);
      }
      // Move it to the front.
      bringToFront(iconId);
      return;
    }

    // Otherwise create a new window.
    const icon = loadedIcons.find((i) => i.id === iconId);
    if (!icon) return;

    // If there is a specific component to show, create it:
    let content: React.ReactNode = null;
    if (icon.component) {
      content = (
        <WindowWrapper title={icon.label || icon.name || 'Unknown'}>
          <>{icon.component}</>
        </WindowWrapper>
      );
    } else {
      // If no component, create a placeholder or do nothing.
      content = (
        <WindowWrapper title={icon.label || icon.name || 'Unknown'}>
          <p>No component found for this icon.</p>
        </WindowWrapper>
      );
    }

    const newWin: DesktopWindow = {
      id: iconId,
      title: icon.label || icon.name || 'Unknown',
      x: 100,
      y: 100,
      width: 400,
      height: 300,
      zIndex: highestZ + 1,
      minimized: false,
      maximized: false,
      content,
    };

    setHighestZ((prev) => prev + 1);
    setWindows((prev) => [...prev, newWin]);
  };

  /** Right‐click on an icon -> open context menu */
  const handleIconContextMenu = (e: React.MouseEvent, iconId: string) => {
    e.preventDefault();
    setContextMenu({
      visible: true,
      x: e.clientX,
      y: e.clientY,
      iconId,
    });
  };

  /** Clicking the desktop hides any open context menu. */
  const handleDesktopClick = () => {
    if (contextMenu.visible) {
      setContextMenu({ visible: false, x: 0, y: 0, iconId: null });
    }
  };

  /** "Open" from the context menu. */
  const handleOpenFromContextMenu = () => {
    if (contextMenu.iconId) {
      handleIconDoubleClick(contextMenu.iconId);
    }
    setContextMenu({ visible: false, x: 0, y: 0, iconId: null });
  };

  /** Move a window to the front. */
  const bringToFront = (id: string) => {
    setHighestZ((prev) => prev + 1);
    setWindows((prev) =>
      prev.map((w) => (w.id === id ? { ...w, zIndex: highestZ + 1 } : w))
    );
  };

  /** Close a window completely. */
  const closeWindow = (id: string) => {
    setWindows((prev) => prev.filter((w) => w.id !== id));
  };

  /** Minimize or restore a window. */
  const toggleMinimizeWindow = (id: string) => {
    setWindows((prev) =>
      prev.map((w) =>
        w.id === id
          ? { ...w, minimized: !w.minimized, maximized: false }
          : w
      )
    );
  };

  /** Maximize or restore a window. */
  const toggleMaximizeWindow = (id: string) => {
    setWindows((prev) =>
      prev.map((w) =>
        w.id === id
          ? { ...w, maximized: !w.maximized, minimized: false }
          : w
      )
    );
    bringToFront(id);
  };

  /** Handle dragging a window by its title bar. */
  const handleMouseDownOnTitle = (
    e: React.MouseEvent,
    id: string
  ) => {
    e.preventDefault();
    bringToFront(id);

    const startX = e.clientX;
    const startY = e.clientY;

    // Find the window's current position:
    const win = windows.find((w) => w.id === id);
    if (!win) return;

    const initialX = win.x;
    const initialY = win.y;

    const onMouseMove = (moveEvent: MouseEvent) => {
      const dx = moveEvent.clientX - startX;
      const dy = moveEvent.clientY - startY;

      setWindows((prev) =>
        prev.map((w) =>
          w.id === id
            ? { ...w, x: initialX + dx, y: initialY + dy }
            : w
        )
      );
    };

    const onMouseUp = () => {
      window.removeEventListener('mousemove', onMouseMove);
      window.removeEventListener('mouseup', onMouseUp);
    };

    window.addEventListener('mousemove', onMouseMove);
    window.addEventListener('mouseup', onMouseUp);
  };

  /** Handle dragging from the bottom‐right resize handle. */
  const handleResizeMouseDown = (
    e: React.MouseEvent,
    id: string
  ) => {
    e.preventDefault();
    e.stopPropagation();
    bringToFront(id);

    const startX = e.clientX;
    const startY = e.clientY;

    const win = windows.find((w) => w.id === id);
    if (!win) return;

    const initWidth = win.width;
    const initHeight = win.height;

    const onMouseMove = (moveEvent: MouseEvent) => {
      const dx = moveEvent.clientX - startX;
      const dy = moveEvent.clientY - startY;

      setWindows((prev) =>
        prev.map((w) =>
          w.id === id
            ? {
                ...w,
                width: Math.max(200, initWidth + dx),
                height: Math.max(100, initHeight + dy),
              }
            : w
        )
      );
    };

    const onMouseUp = () => {
      window.removeEventListener('mousemove', onMouseMove);
      window.removeEventListener('mouseup', onMouseUp);
    };

    window.addEventListener('mousemove', onMouseMove);
    window.addEventListener('mouseup', onMouseUp);
  };

  /** Render all non-minimized windows. */
  const renderWindows = windows.map((w) => {
    if (w.minimized) return null;

    const style: React.CSSProperties = {
      left: w.x,
      top: w.y,
      width: w.maximized ? '100%' : w.width,
      height: w.maximized ? '100%' : w.height,
      zIndex: w.zIndex,
    };

    const icon = loadedIcons.find((i) => i.id === w.id);

    return (
      <WindowFrame
        key={w.id}
        style={style}
        onMouseDown={() => bringToFront(w.id)}
        maximized={w.maximized}
        isTopmost={w.zIndex === highestZ}
      >
        <TitleBar onMouseDown={(e) => handleMouseDownOnTitle(e, w.id)}>
          <TitleBarLeft>
            {icon?.icon && <TitleBarButtons>{icon.icon}</TitleBarButtons>}
          </TitleBarLeft>
          <TitleBarText>{w.title}</TitleBarText>
          <TitleBarButtons>
            <TitleBarButtons onClick={() => toggleMinimizeWindow(w.id)}>_</TitleBarButtons>
            <TitleBarButtons onClick={() => toggleMaximizeWindow(w.id)}>
              {w.maximized ? '🗗' : '🗖'}
            </TitleBarButtons>
            <TitleBarButtons onClick={() => closeWindow(w.id)}>X</ TitleBarButtons>
          </TitleBarButtons>
        </TitleBar>
        <ContentArea>{w.content}</ContentArea>
        <ResizeHandle onMouseDown={(e) => handleResizeMouseDown(e, w.id)} />
      </WindowFrame>
    );
  });

  // Taskbar items for minimized windows:
  const minimizedWindows = windows.filter((w) => w.minimized);

  return (
    <DesktopContainer onClick={handleDesktopClick}>
      {/* Background video layer */}
      <video
        key={currentVideo} // Key to force re-render on source change
        style={{
          position: 'absolute',
          top: 0,
          left: 0,
          width: '100%',
          height: '100%',
          objectFit: 'cover',
          zIndex: 0,
          pointerEvents: 'none', // Makes the video non-interactive
          transition: 'opacity 2s ease-in-out', // Smooth transition
          opacity: 0.5, // Initial opacity for blending
        }}
        src={currentVideo}
        autoPlay
        loop
        muted
        onLoadedData={(e) => {
          const videoElement = e.currentTarget;
          videoElement.style.opacity = '1'; // Fade in the new video
          setTimeout(() => {
            videoElement.style.opacity = '0.5'; // Fade out to blend
          }, 8000); // Start fade out 2 seconds before the next change
        }}
      />

      {/* System Group Control */}
      <SystemGroup>
        System
        <GoogleLoginButton>Login with Google</GoogleLoginButton>
      </SystemGroup>

      {/* Icons on the desktop */}
      {loadedIcons.map((icon) => (
        <DesktopIcon
          tilePosition={icon.tilePosition}
          key={icon.id || icon.name} // fallback if icon.id is missing
          onDoubleClick={() => {
            if (icon.id) {
              handleIconDoubleClick(icon.id);
            } else if (icon.action) {
              // If there's no ID, you could still do something else
              handleIconDoubleClick(icon.name || '');
            }
          }}
          onContextMenu={(e) =>
            handleIconContextMenu(e, icon.id ?? icon.name ?? 'default-icon')
          }
          style={{
            left: icon.x,
            top: icon.y,
            width: icon.width,
            height: icon.height,
            color: icon.color,
          }}
        >
          {icon.image ? (
            <IconSymbol style={{ backgroundImage: `url(${icon.image})` }} />
          ) : (
            <IconSymbol
              style={{
                background: 'none',
                border: 'none',
                fontSize: '40px',
                lineHeight: '60px',
              }}
            >
              {icon.icon}
            </IconSymbol>
          )}
          <IconLabel>{icon.label || icon.name}</IconLabel>
        </DesktopIcon>
      ))}

      {/* Rubbish Bin Icon (placeholder) */}
      <RubbishBinIcon />

      {/* Right‐click context menu */}
      {contextMenu.visible && (
        <ContextMenuContainer
          style={{ left: contextMenu.x, top: contextMenu.y }}
        >
          <ContextMenuItem onClick={handleOpenFromContextMenu}>
            Open {loadedIcons.find((i) => i.id === contextMenu.iconId)?.label}
          </ContextMenuItem>
          <ContextMenuItem>Properties</ContextMenuItem>
          <ContextMenuItem>Delete</ContextMenuItem>
        </ContextMenuContainer>
      )}

      {/* Render all open windows */}
      {renderWindows}

      {/* Taskbar + minimized windows */}
      <Taskbar>
        {minimizedWindows.map((w) => {
          const icon = loadedIcons.find((i) => i.id === w.id);
          return (
            <TaskbarButton
              key={w.id}
              onClick={() => toggleMinimizeWindow(w.id)}
              title={w.title}
            >
              {icon?.icon || '❓'}
            </TaskbarButton>
          );
        })}
      </Taskbar>
    </DesktopContainer>
  );
};

export default Desktop;
