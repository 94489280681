import { TILES_PER_ROW, TILE_SIZE, TILES_PER_COLUMN } from "../../constants";


/** Utility function for choosing a random tile from your sprite sheet. */
const getRandomTilePosition = () => {
    const x = Math.floor(Math.random() * TILES_PER_ROW) * TILE_SIZE;
    const y = Math.floor(Math.random() * TILES_PER_COLUMN) * TILE_SIZE;
    return { x, y };
  };
  
  // A simple wrapper so each window can display a title and content nicely.
  const WindowWrapper: React.FC<{ title: string, children: React.ReactNode }> = ({ title, children }) => (
    <div style={{ border: '2px solid #0ff', padding: '10px', margin: '10px' }}>
      <h2 style={{ color: '#0ff' }}>{title}</h2>
      {children}
    </div>
  );
  

  export { getRandomTilePosition, WindowWrapper };