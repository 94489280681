/* ================== Styled Components + Neon Cyberpunk CSS ================== */

import styled, { keyframes } from "styled-components";
import { SHEET_WIDTH, SHEET_HEIGHT } from "../../constants";

// Flicker animation for a neon glow effect
const neonFlicker = keyframes`
  0%   { filter: brightness(1.0); }
  50%  { filter: brightness(1.3); }
  100% { filter: brightness(1.0); }
`;

// "Matrix rain" background effect
const matrixRain = keyframes`
  0%   { transform: translateY(-100%); opacity: 0; }
  50%  { opacity: 1; }
  100% { transform: translateY(100%); opacity: 0; }
`;

// New keyframes for the 3-body problem animation
const threeBodyProblem = keyframes`
  0% {
    transform: translate(0, 0) rotate(0deg);
  }
  33% {
    transform: translate(50px, 50px) rotate(120deg);
  }
  66% {
    transform: translate(-50px, -50px) rotate(240deg);
  }
  100% {
    transform: translate(0, 0) rotate(360deg);
  }
`;

// Updated neon flicker with purple hue
const neonFlickerPurple = keyframes`
  0%   { filter: brightness(1.0); }
  50%  { filter: brightness(1.5); }
  100% { filter: brightness(1.0); }
`;

// Updated matrix rain with purple hue
const matrixRainPurple = keyframes`
  0%   { transform: translateY(-100%); opacity: 0; }
  50%  { opacity: 1; }
  100% { transform: translateY(100%); opacity: 0; }
`;

// Keyframes for fading out the video
const videoFadeOut = keyframes`
  0%, 80% {
    opacity: 1;
  }
  100% {
    opacity: 0;
  }
`;

/** The full-screen container for the desktop. */
const DesktopContainer = styled.div`
  width: 100vw;
  height: 100vh;
  position: relative;
  overflow: hidden;
  font-family: 'Courier New', Courier, monospace;
  color: #00ff00; /* Neon green text */
  background: linear-gradient(135deg, #1a1a1a, #2c2c2c); /* Smooth dark gray gradient */

  &:before {
    content: '';
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: repeating-linear-gradient(
      0deg,
      transparent,
      transparent 2px,
      rgba(128, 0, 128, 0.15) 2px, /* Softer purple hue */
      rgba(128, 0, 128, 0.15) 4px
    );
    animation: ${matrixRainPurple} 5s linear infinite, ${threeBodyProblem} 10s linear infinite;
    z-index: -1;
  }
`;

/** A styled icon container on the desktop. */
const DesktopIcon = styled.div<{ tilePosition?: { x: number; y: number } }>`
  width: 64px;
  height: 64px;
  margin: 20px;
  text-align: center;
  cursor: pointer;
  position: absolute;
  color: #fff;
  background-position: ${({ tilePosition }) =>
    tilePosition ? `-${tilePosition.x}px -${tilePosition.y}px` : '0 0'};
  background-size: ${SHEET_WIDTH}px ${SHEET_HEIGHT}px;
  border: 2px solid #0ff;
  box-shadow: 0 0 5px #0ff, 0 0 10px #0ff, 0 0 15px #0ff, 0 0 20px #0ff;

  &:hover {
    color: #0ff;
    box-shadow: 0 0 10px #0ff, 0 0 20px #0ff, 0 0 30px #0ff, 0 0 40px #0ff;
  }
`;

/** If you have a sprite sheet, you can also set background-image: url('media/icons.png'); */

/** Inner symbol or image for an icon. */
const IconSymbol = styled.div`
  width: 48px;
  height: 48px;
  margin: 0 auto;
  background-size: cover;
  border: 2px solid #0ff;
  animation: ${neonFlickerPurple} 2s infinite;
  box-shadow: 0 0 10px rgba(0, 255, 255, 0.5); /* Smooth shadow */
`;

const IconLabel = styled.div`
  margin-top: 5px;
  font-size: 12px;
  font-family: 'Neon', sans-serif;
  text-shadow: 0 0 5px #0ff, 0 0 10px #0ff, 0 0 15px #0ff, 0 0 20px #0ff;
`;

/** Simple right-click context menu container. */
const ContextMenuContainer = styled.div`
  position: absolute;
  background: #222;
  border: 1px solid #0ff;
  color: #0ff;
  z-index: 9999;
  animation: ${neonFlickerPurple} 2s infinite;
  box-shadow: 0 0 15px rgba(0, 255, 255, 0.5); /* Smooth shadow */
`;

const ContextMenuItem = styled.div`
  padding: 8px 16px;
  cursor: pointer;

  &:hover {
    background: #0ff;
    color: #000;
  }
`;

/** Window frame styling */
const WindowFrame = styled.div<{ maximized: boolean; isTopmost: boolean }>`
  position: absolute;
  background: rgba(0, 0, 0, 0.8); /* Semi-transparent dark background */
  border: 4px solid ${({ isTopmost }) => (isTopmost ? '#800080' : '#550055')}; /* Purple border */
  border-radius: 15px;
  box-shadow: 
    0 0 20px ${({ isTopmost }) => (isTopmost ? 'rgba(128, 0, 128, 0.7)' : 'rgba(85, 0, 85, 0.7)')}; /* Smooth shadow */
  overflow: hidden;
  color: white;
  display: flex;
  flex-direction: column;

  ${({ maximized }) =>
    maximized &&
    `
      left: 0 !important;
      top: 0 !important;
      width: 100%!important;
      height: 100%!important;
      border-radius: 0;
    `}
`;

const TitleBar = styled.div`
  background: linear-gradient(90deg, #550000, #ff0000);
  height: 50px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  color: white;
  text-shadow: 1px 1px 3px black;
  padding: 0 15px;
  border-bottom: 2px solid #550000;
  font-family: 'Orbitron', sans-serif;
  font-size: 18px;
`;

const TitleBarLeft = styled.div`
  display: flex;
  align-items: center;
`;

const IconInTitleBar = styled.div`
  width: 32px;
  height: 32px;
  background-size: contain;
  background-repeat: no-repeat;
  margin-right: 10px;
`;

const TitleBarText = styled.div`
  font-weight: bold;
  font-family: 'Neon', sans-serif;
  text-shadow: 0 0 5px #0ff, 0 0 10px #0ff, 0 0 15px #0ff, 0 0 20px #0ff;
`;

const TitleBarButtons = styled.div`
  display: flex;
  gap: 8px;
`;

const TitleBarButton = styled.button`
  background: linear-gradient(135deg, #990000, #ff0000);
  border: none;
  color: white;
  font-size: 18px;
  font-weight: bold;
  width: 40px;
  height: 40px;
  border-radius: 50%;
  box-shadow: 0 0 10px #ff0000;
  cursor: pointer;
  transition: transform 0.2s, box-shadow 0.2s;

  &:hover {
    transform: scale(1.1);
    box-shadow: 0 0 20px #ff3333;
  }
`;

const ContentArea = styled.div`
  flex: 1;
  background: rgba(0, 0, 0, 0.6);
  padding: 20px;
  overflow-y: auto;
  color: white;
  font-family: 'Orbitron', sans-serif;
  font-size: 14px;
  box-shadow: inset 0 0 10px #ff0000;
`;

const ResizeHandle = styled.div`
  width: 20px;
  height: 20px;
  background: linear-gradient(135deg, #550000, #ff0000);
  position: absolute;
  bottom: 0;
  right: 0;
  border-radius: 50%;
  cursor: nwse-resize;
  box-shadow: 0 0 10px #ff0000;

  &:hover {
    box-shadow: 0 0 20px #ff3333;
  }
`;

/** Taskbar at the bottom of the screen. */
const Taskbar = styled.div`
  position: absolute;
  bottom: 0;
  left: 0;
  height: 50px;
  width: 100%;
  background: linear-gradient(90deg, #4b0082, #6a0dad); /* Smooth purple gradient */
  border-top: 2px solid #6a0dad;
  display: flex;
  align-items: center;
  justify-content: space-around;
  padding: 0 15px;
  z-index: 1000; /* Keep above other elements */
  box-shadow: 0px -2px 10px rgba(0, 0, 0, 0.3); /* Smooth shadow */
`;

const TaskbarButton = styled.div`
  width: 40px;
  height: 40px;
  background: #ddeaf6; /* Light XP-style button color */
  border: 1px solid #8eb8e5; /* Border matches XP-like theme */
  border-radius: 6px;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 20px;
  color: black;
  cursor: pointer;
  transition: transform 0.2s, background 0.2s;

  &:hover {
    background: #c5dbf2; /* Slightly darker blue on hover */
  }

  &:active {
    background: #a7c8eb; /* Darker blue when pressed */
    transform: scale(0.95);
  }
`;

/** Updated VideoBackground with gradient overlay and fade-out effect */
const VideoBackground = styled.video`
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  object-fit: cover;
  z-index: -1;
  opacity: 1;
  animation: ${videoFadeOut} 10s linear infinite;

  &:before {
    content: '';
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: linear-gradient(135deg, rgba(0, 255, 255, 0.5), rgba(0, 0, 255, 0.5)); /* Neon and blue gradient */
    z-index: 1;
    pointer-events: none;
  }
`;

/** A Google login button in the top-right system area. */
const GoogleLoginButton = styled.button`
  position: absolute;
  top: 10px;
  right: 10px;
  background-color: #4285f4;
  color: white;
  border: none;
  padding: 10px 20px;
  cursor: pointer;
  border-radius: 5px;
  font-size: 14px;

  &:hover {
    background-color: #357ae8;
  }
`;

/** System area in top-right corner. */
const SystemGroup = styled.div`
  position: absolute;
  top: 0;
  right: 0;
  padding: 20px;
  border: 1px solid rgba(255, 255, 255, 0.2);
  border-radius: 10px;
  color: rgba(255, 255, 255, 0.2);
  font-size: 12px;
  text-align: center;
`;

/** A placeholder for the Rubbish Bin icon. */
const RubbishBinIcon = styled.div`
  position: absolute;
  bottom: 20px;
  right: 20px;
  width: 64px;
  height: 64px;
  background-image: url('path/to/rubbish-bin-icon.png');
  background-size: cover;
  cursor: pointer;
`;

export { DesktopContainer, DesktopIcon, IconSymbol, IconLabel, ContextMenuContainer, ContextMenuItem, WindowFrame, TitleBar, TitleBarLeft, TitleBarText, TitleBarButtons, ContentArea, ResizeHandle, Taskbar, TaskbarButton, VideoBackground, GoogleLoginButton, SystemGroup, RubbishBinIcon };